import axios from "axios";
import React, { useState } from "react";

const Chatbox = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);

  const handleSend = async () => {
    if (!input.trim()) return;

    setMessages([...messages, { user: "Me", text: input }]);

    try {
      const response = await axios.post("http://localhost:3002/chat", {
        message: input,
      });

      const aiResponse = response.data.message;
      setMessages((prevMessages) => [
        ...prevMessages,
        { user: "AI", text: aiResponse },
      ]);
      setInput("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="chatbox">
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.user}`}>
            <strong>{msg.user}: </strong>
            {msg.text}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Írj valamit..."
        />
        <button onClick={handleSend}>Küldés</button>
      </div>
    </div>
  );
};

export default Chatbox;
