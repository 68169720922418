import React from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TITLE = "Home | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
//const CANONICAL = Config.SITE_DOMAIN + "/";

class HomeDe extends React.Component {
  render() {
    return (
      <main onload="setThemeMain()">
        <Helmet>
          <title>{TITLE}</title>
          <Link rel="canonical" href="{CANONICAL}"></Link>
          <meta name="description" content={DESC} />
          <meta name="theme-color" content={Config.THEME_COLOR} />
          <meta
            name="description"
            content="Entdecken Sie die neuesten Trends, Tools und Innovationen der Künstlichen Intelligenz (KI). Unser Blog beleuchtet den Einsatz von KI im Alltag, von Smart-Technologien bis hin zu KI-gesteuerten Spielen und nützlichen Tipps für den effektiven Einsatz von KI."
          />
        </Helmet>
        {/* Hero szekció */}
        <section className="hero  col-12 col-sm-12 col-md-12 col-lg-12 ">
          <h1>Entdecken Sie, wie künstliche Intelligenz den Alltag prägt.</h1>
        </section>
        {/* Cookie Consent Modal */}
        <div id="cookieModal" className="cookie-modal">
          <div className="cookie-modal-content">
            <h3>The site uses cookies</h3>
            <p>
              Our website uses cookies to improve the user experience use.
              Please accept cookies for the full user experience in order to
            </p>
            <div className="cookie-buttons">
              <button
                id="acceptCookies"
                className="btn btn-AIinsideLife-primary"
              >
                I accept it
              </button>
              <button id="rejectCookies" className="btn btn-secondary">
                I refuse
              </button>
            </div>
          </div>
        </div>

        {/* Legfrissebb bejegyzések */}
        <section className="latest-posts ">
          <h2>
            <b>Neueste Beiträge</b>
          </h2>
          <div className="container mt-5">
            <article className="post post-left row mb-4 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/flux-tiktok.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-8 mx-2 shadow-sm rounded">
                <h3>KI-Trends im Jahr 2024</h3>
                <h3>TikTok-Notizen, Instagrams Konkurrent:</h3>
                <p>
                  Entdecken Sie die neuesten KI-Trends und -Technologien
                  dominieren im Jahr 2024. TikTok hat sich einen mutigen Schritt
                  ausgedacht, und kündigte kürzlich einen neuen Wettbewerb mit
                  Instagram an bevorstehende Einführung der
                  Foto-Sharing-Anwendung. Leistungsstarke KI, die einzigartige
                  Benutzer von TikTok mithilfe von Algorithmen erreichen möchten
                  Bieten Sie ein Erlebnis und fordern Sie so Instagram heraus
                  das Foto-Sharing-Segment der sozialen Medien. Laut der
                  Nachrichten, es ist App wird innovative Funktionen nutzen und
                  die App wird bieten eine neue Perspektive auf
                  Social-Media-Interaktionen. In einem Die an die Nutzer
                  gesendete Erklärung wurde von TikTok in Fotobeiträgen
                  veröffentlicht namens „TikTok Notes“. plant die
                  Veröffentlichung eines spezialisierten Plattform.
                </p>
                <Link to="/Blogs/AITrendek">Werde weiterlesen</Link>
              </div>
            </article>
            <article className="post post-right row mb-4 py-3 shadow">
              <div className="col-9">
                <h3>Maschinelles Lernen leicht gemacht</h3>
                <h3>
                  Erfahren Sie alles über maschinelles Lernen auf einfache und
                  einfache Weise verständliche Weise.
                </h3>

                <p>
                  Maschinelles Lernen ist wichtig, weil es Unternehmen eine
                  Möglichkeit bietet Blick auf Trends im Kundenverhalten und im
                  operativen Geschäft Mustern und unterstützt die Entwicklung
                  neuer Produkte. Viele der heute führenden Unternehmen wie
                  Facebook, Google, und Uber machen maschinelles Lernen zu einem
                  zentralen Bestandteil ihres Unternehmens Operationen.
                </p>
                <Link to="/Blogs/AITrendek">Werde weiterlesen</Link>
              </div>
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/machine-learning.webp"
                  alt="Bejegyzés kép"
                />
              </div>
            </article>
            <article className="post post-left row mb-3 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2 "
                  src="../images/jasperai.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-9">
                <h3>KI-Tools für Unternehmen</h3>
                <p>
                  Die besten KI-Tools zur Steigerung der Effizienz Ihres
                  Unternehmens.
                </p>
                <p>
                  Viele Menschen kennen Jasper als den besten
                  KI-Schreibassistenten überhaupt ist mit seinen beeindruckenden
                  Funktionen und seiner Qualität führend auf dem Markt. Zuerst
                  müssen Sie die ersten Wörter angeben, die Jasper analysiert.
                  bevor Sie Phrasen, Absätze oder Dokumente auf der Grundlage
                  erstellen zu Thema und Ton. Weniger als 1.500 Minuten können
                  schreiben ein 15-Wörter-Artikel unten.
                </p>
                <Link to="/Blogs/AITrendek">Werde weiterlesen</Link>
              </div>
            </article>
          </div>
        </section>
        {/* AI Eszközök */}
        <section className="tools container">
          <h2>Empfohlene KI-Tools</h2>
          <div className="tools-list">
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Robovision-pink-logo-banner.jpg"
                alt="AI Eszköz Robovision-pink-logo-banner.jpg"
              />
              <h3>KI-gestützte Computer-Vision-Plattform.</h3>
              <p>Leistungsstarkes KI-Tool zur Bilderkennung.</p>
              <p>
                Robovision bietet eine Vision-KI-Plattform, die die gesamte KI
                abdeckt Lebenszyklus. Optimieren Sie den gesamten
                Entwicklungsprozess, Implementierung und Anpassung von KI in das
                sich ständig verändernde Geschäft von heute Umfeld. Erstellen
                von Anwendungen für komplexe Computer Vision Herausforderungen
                zu bewältigen war dank der Leistungsfähigkeit der KI noch nie
                einfacher die Robovision-Plattform.
              </p>
              <Link to="/tools/tool-1">Erfahren Sie mehr</Link>
            </div>
            <div className="tool-item-u">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/freshdesk-logo.svg"
                alt="AI Eszköz Freshdesk"
              />
              <h3>Freshdesk</h3>
              <p>KI-basierte Kundendienstlösung.</p>
              <p>Bieten Sie mühelosen Kundenservice mit Freshdesk</p>
              <Link to="/tools/tool-2">Erfahren Sie mehr</Link>
            </div>
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Bard-AI-Logo.png"
                alt="AI Eszköz Bard AI"
              />
              <h3>Bard AI</h3>
              <p>Ein KI-Tool, das hilft, die Produktivität zu steigern.</p>
              <p>
                Bard ist ein experimenteller Konversations-KI-Dienst, der von
                entwickelt wurde Google, unterstützt durch sein Sprachmodell für
                Dialoganwendungen (LaMDA). Es ist darauf ausgelegt, qualitativ
                hochwertige, frische Antworten zu liefern durch das Abrufen von
                Informationen aus dem Internet. Bard-Attribute direkt lange
                Zitate zur Quellwebseite und bietet Benutzern die Möglichkeit,
                das Original anzuzeigen und möglicherweise darauf zu klicken
                Quelle über URLs oder Bildminiaturansichten zur Überprüfung und
                für weitere Zwecke Erforschung.
              </p>
              <Link to="/tools/tool-3">Erfahren Sie mehr</Link>
            </div>
          </div>
        </section>
        {/* Hírlevél feliratkozás */}
        <section className="newsletter container">
          <h2>Abonnieren Sie unseren Newsletter!</h2>
          <p>
            Verpassen Sie nicht die neuesten Trends und Innovationen im Bereich
            KI.
          </p>
          <form>
            <input type="email" placeholder="E-Mail-Adresse" />
            <button type="submit">Abonnement</button>
          </form>
        </section>
      </main>
    );
  }
}

export default HomeDe;
