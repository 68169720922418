import React from "react";


import {Helmet} from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";


const TITLE = "General | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";
class General extends React.Component{
    render() {
        return (
            <main  >
                <Helmet>
                    <title>{TITLE}</title>
                    <Link rel="canonical" href="{CANONICAL}"></Link>
                    <meta name="description" content={DESC} />
                    <meta name="theme-color" content={Config.THEME_COLOR}/>
                </Helmet>
                <div  className="container d-flex justify-content-center" >
            <div id="container" className="container-fluid ">
             <img id="mse43-logo" src="../images/MSE43Logo (2).png" alt="mse43-logo" className="rounded img-fluid  d-block"/>
            </div>      
         </div>
         <div>
            lorem50kőosldgkav, lcxv,élsktpőgwáélsdpőkgőarkzúpogrwkPJFPEOJF

         </div>
            </main>
              
        )
    }
}

export default General;