// BlogPost.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import aiArticles from "../Articles/AITrendek.json"; // A JSON fájl importálása

const AiTrendsHu = () => {
  const { articleId } = useParams(); // Az URL-ből vesszük a cikk azonosítót
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const selectedArticle = aiArticles[0]; // Cikk keresése az azonosító alapján
    if (selectedArticle) {
      setArticle(selectedArticle);
    }
  }, [articleId]);

  if (!article) {
    return <p>A cikk nem található.</p>; // Ha a cikk nem található, hibát jelenít meg
  }

  return (
    <div className="blog-post container">
      <h1>{article.title}</h1>
      <h2>{article.subtitle}</h2>
      <p>{article.content}</p>
      {/* További Lorem Ipsum szöveg a teszteléshez */}
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
        odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
        quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
        mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
        Vestibulum lacinia arcu eget nulla.
      </p>
    </div>
  );
};

export default AiTrendsHu;
