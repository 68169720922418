import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { useNavigate } from "react-router-dom";
import articlesData from "../Articles/ArticlesEn.json"; // Import articles.json

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const AitoolsDe = () => {
  const navigate = useNavigate();

  // State to store the selected category, default is "New"
  const [selectedCategory, setSelectedCategory] = useState("AITools");
  const [filteredArticles, setFilteredArticles] = useState([]);

  // Function to filter articles by category
  const filterArticlesByCategory = (category) => {
    const filtered = articlesData.filter((article) =>
      article.category.includes(category)
    );
    setFilteredArticles(filtered);
  };

  // Load articles for the default category (New) on component mount
  useEffect(() => {
    filterArticlesByCategory("AITools");
  }, []);

  // Handle category change on button click
  const handleCategoryChange = (category, categoryName) => {
    setSelectedCategory(categoryName);
    filterArticlesByCategory(category);
  };

  // Function to navigate to the Article component and pass the article ID
  const handleReadMore = (articleId) => {
    navigate("/article", { state: { articleId } });
  };

  return (
    <main className="main-content">
      <Helmet>
        <title>{TITLE}</title>
        <link rel="canonical" href={CANONICAL}></link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <div className="w-100 content-center">
        <div className="row">
          {/* Category buttons */}
          <div className="col mt-3">
            <button
              className="btn btn-AIinsideLife-primary ms-3"
              onClick={() => handleCategoryChange("Trend", "AI Trendek")}
            >
              AI Trendek
            </button>
            <button
              className="btn btn-AIinsideLife-primary ms-3"
              onClick={() => handleCategoryChange("New", "Újdonság")}
            >
              Newness
            </button>
            <button
              className="btn btn-AIinsideLife-primary ms-3"
              onClick={() =>
                handleCategoryChange("Recomment", "Ajánlott AI eszközök")
              }
            >
              Ajánlott AI eszközök
            </button>
            <button
              className="btn btn-AIinsideLife-primary ms-3"
              onClick={() =>
                handleCategoryChange("Machine Learning", "Gépi tanulás")
              }
            >
              Gépi tanulás
            </button>
            <button
              className="btn btn-AIinsideLife-primary ms-3"
              onClick={() => handleCategoryChange("Game", "AI Játékok")}
            >
              AI Játékok
            </button>
          </div>

          {/* Display filtered articles in two responsive columns */}
          <div className="col-12">
            {/* Dynamic title based on selected category */}
            <div className="articleText">
              <h1>{selectedCategory}</h1>
            </div>
            <div
              className="row gx-3"
              style={{ marginRight: "50px", marginLeft: "50px" }}
            >
              {filteredArticles.map((article, index) => (
                <div className="col-md-6 mb-3" key={article.ID}>
                  <div className="card">
                    <div className="row g-0">
                      <div className="col-md-4">
                        <img
                          src={article.img}
                          className="img-fluid rounded-start"
                          alt={article.alt}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-title">{article.title}</h5>
                          <p className="card-text">{article.teaser}</p>
                          <button
                            onClick={() => handleReadMore(article.ID)}
                            className="btn btn-AIinsideLife-primary"
                          >
                            Read More...
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AitoolsDe;
