import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomeHu from "./pages/HomeHu";
import HomeEn from "./pages/HomeEn";
import HomeDe from "./pages/HomeDe";
import HomeEs from "./pages/HomeEs";
import HomeFr from "./pages/HomeFr";
import BlogHu from "./pages/BlogHu";
import BlogPost from "./Blogs/BlogPost";
import BlogDe from "./pages/BlogDe";
import BlogEn from "./pages/BlogEn";

import BlogEs from "./pages/BlogEs";
import BlogFr from "./pages/BlogFr";
import Article from "./Blogs/Article"; // Importáld az Article komponens
import Aitools from "./pages/Aitools";
import AitoolsHu from "./pages/AitoolsHu";
import AitoolsDe from "./pages/AitoolsDe";
import AitoolsFr from "./pages/AitoolsFr";
import AitoolsEs from "./pages/AitoolsEs";
import LearningEn from "./pages/LearningEn";
import LearningHu from "./pages/LearningHu";
import LearningDe from "./pages/LearningDe";
import Attendance from "./pages/Attendance";
import ContactHu from "./pages/ContactHu";
import ContactEn from "./pages/ContactEn";
import ContactDe from "./pages/ContactDe";
import ContactEs from "./pages/ContactEs";
import ContactFr from "./pages/ContactFr";
import AiTrendsHu from "./Blogs/AITrendsHu";
import AiTrendsPost from "./Blogs/AITrendek";
import General from "./pages/General";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageNotFound from "./components/PageNotFound";
import LinkOpenError from "./components/LinkOpenError";
import PrivacyPolicy from "./components/PrivacyPolicy";

import "bootstrap/dist/js/bootstrap.bundle.min.js"; // JS + Popper.js

import "./App.css";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/HomeHu" element={<HomeHu />} />
          <Route path="/" element={<Navigate to="/HomeEn" replace={true} />} />
          {/* replace={true} biztosítja, hogy ne kerüljön vissza a böngésző történetébe */}
          <Route path="/HomeEn" element={<HomeEn />} />
          <Route path="/HomeDe" element={<HomeDe />} />
          <Route path="/HomeEs" element={<HomeEs />} />
          <Route path="/HomeFr" element={<HomeFr />} />
          <Route path="/BlogHu" element={<BlogHu />} />
          <Route path="/BlogPost" element={<BlogPost />} />
          <Route path="/BlogDe" element={<BlogDe />} />

          <Route path="/BlogEn" element={<BlogEn />} />
          <Route path="/BlogEs" element={<BlogEs />} />
          <Route path="/BlogFr" element={<BlogFr />} />
          <Route path="/Article" element={<Article />} />
          <Route path="/Aitools" element={<Aitools />} />
          <Route path="/AitoolsHu" element={<AitoolsHu />} />
          <Route path="/AitoolsDe" element={<AitoolsDe />} />
          <Route path="/AitoolsFr" element={<AitoolsFr />} />
          <Route path="/AitoolsEs" element={<AitoolsEs />} />
          <Route path="/LearningEn" element={<LearningEn />} />
          <Route path="/LearningHu" element={<LearningHu />} />
          <Route path="/LearningDe" element={<LearningDe />} />
          <Route path="/ContactHu" element={<ContactHu />} />
          <Route path="/ContactEn" element={<ContactEn />} />
          <Route path="/ContactDe" element={<ContactDe />} />
          <Route path="/ContactEs" element={<ContactEs />} />
          <Route path="/ContactFr" element={<ContactFr />} />
          <Route path="/Attendance" element={<Attendance />} />
          {/*<Route path="/Blogs/AITrendek" element={<AiTrendsPost />} /> */}
          <Route
            path="/Blogs/AITrendek/:articleId"
            element={<AiTrendsPost />}
          />
          <Route path="/AiTrendsHu" element={<AiTrendsHu />} />
          <Route path="/link-open-error" element={<LinkOpenError />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
