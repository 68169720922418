import React from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";

const TITLE = "Home | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
//const CANONICAL = Config.SITE_DOMAIN + "/";

class HomeFr extends React.Component {
  render() {
    return (
      <main onload="setThemeMain()">
        <Helmet>
          <title>{TITLE}</title>
          <Link rel="canonical" href="{CANONICAL}"></Link>
          <meta name="description" content={DESC} />
          <meta name="theme-color" content={Config.THEME_COLOR} />
          <meta
            name="description"
            content="Découvrez les dernières tendances, outils et innovations de l'Intelligence Artificielle (IA). Notre blog couvre l'utilisation de l'IA dans la vie quotidienne, des technologies intelligentes aux jeux basés sur l'IA, ainsi que des conseils pratiques pour utiliser l'IA efficacement."
          />
        </Helmet>
        {/* Hero szekció */}
        <section className="hero  col-12 col-sm-12 col-md-12 col-lg-12 ">
          <h1>
            Découvrez comment l'intelligence artificielle façonne la vie
            quotidienne.
          </h1>
        </section>

        {/* Cookie Consent Modal */}
        <div id="cookieModal" className="cookie-modal">
          <div className="cookie-modal-content">
            <h3>Le site utilise des cookies</h3>
            <p>
              Notre site Web utilise des cookies pour améliorer l'expérience
              utilisateur. Veuillez accepter les cookies pour une expérience
              utilisateur complète afin de
            </p>
            <div className="cookie-buttons">
              <button
                id="acceptCookies"
                className="btn btn-AIinsideLife-primary"
              >
                Je l'accepte
              </button>
              <button id="rejectCookies" className="btn btn-secondary">
                Je refuse
              </button>
            </div>
          </div>
        </div>
        {/* Legfrissebb bejegyzések */}
        <section className="latest-posts ">
          <h2>
            <b>L'IA générative dans la vie</b>
          </h2>
          <div className="container mt-5">
            <article className="post post-left row mb-4 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/flux-tiktok.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-8 mx-2 shadow-sm rounded">
                <h3>Tendances de l'IA en 2024</h3>
                <h3>Notes TikTok, le concurrent d'Instagram :</h3>
                <p>
                  Découvrez les dernières tendances et technologies d'IA qu'ils
                  dominer en 2024. TikTok a proposé une décision audacieuse, et
                  a récemment annoncé un nouveau concours avec Instagram le
                  introduction prochaine de l’application de partage de photos.
                  Les puissants utilisateurs uniques d’IA de TikTok utilisant
                  des algorithmes veulent offrir une expérience, défiant ainsi
                  Instagram à un le segment de partage de photos des médias
                  sociaux. Selon le nouvelles, c'est l'application qui utilisera
                  des fonctionnalités innovantes et l'application offrir une
                  nouvelle perspective sur les interactions sur les réseaux
                  sociaux. Dans un déclaration envoyée aux utilisateurs, TikTok
                  l'a rendue publique sur des publications photo appelé « Notes
                  TikTok ». prévoit de publier un plate-forme.
                </p>
                <Link to="/Blogs/AITrendek">je vais continuer à lire</Link>
              </div>
            </article>
            <article className="post post-right row mb-4 py-3 shadow">
              <div className="col-9">
                <h3>L'apprentissage automatique simplifié</h3>
                <h3>
                  Apprenez tout sur l'apprentissage automatique de manière
                  simple et manière compréhensible.
                </h3>

                <p>
                  L'apprentissage automatique est important car il donne aux
                  entreprises une vue des tendances du comportement des clients
                  et des activités opérationnelles modèles, ainsi que soutient
                  le développement de nouveaux produits. Bon nombre des plus
                  grandes entreprises d'aujourd'hui, comme Facebook, Google, et
                  Uber, font du machine learning un élément central de leur
                  opérations.
                </p>
                <Link to="/Blogs/AITrendek">je vais continuer à lire</Link>
              </div>
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/machine-learning.webp"
                  alt="Bejegyzés kép"
                />
              </div>
            </article>
            <article className="post post-left row mb-3 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2 "
                  src="../images/jasperai.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-9">
                <h3>Outils d'IA pour les entreprises</h3>
                <p>
                  Les meilleurs outils d'IA pour vous aider à accroître
                  l'efficacité de votre entreprise.
                </p>
                <p>
                  Beaucoup de gens connaissent Jasper comme le meilleur
                  assistant d'écriture IA qui domine le marché avec ses
                  caractéristiques et sa qualité impressionnantes. Vous devez
                  d'abord fournir les premiers mots que Jasper analyse, avant
                  d'apporter des phrases, des paragraphes ou des documents créés
                  sur la base sur le sujet et le ton. Moins de 1 500 minutes
                  peuvent écrire un article de 15 mots ci-dessous.
                </p>
                <Link to="/Blogs/AITrendek">je vais continuer à lire</Link>
              </div>
            </article>
          </div>
        </section>
        {/* AI Eszközök */}
        <section className="tools container">
          <h2>
            <b>Outils d'IA recommandés</b>
          </h2>
          <div className="tools-list">
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Robovision-pink-logo-banner.jpg"
                alt="AI Eszköz Robovision-pink-logo-banner.jpg"
              />
              <h3>Plateforme de vision par ordinateur alimentée par l'IA.</h3>
              <p>Puissant outil d’IA pour la reconnaissance d’images.</p>
              <p>
                Robovision propose une plateforme d'IA de vision qui couvre
                l'intégralité de l'IA cycle de vie. Rationalisez l'ensemble du
                processus de développement, mettre en œuvre et adapter l’IA dans
                un secteur d’activité en constante évolution environnement.
                Création d'applications pour la vision par ordinateur complexe
                les défis n'ont jamais été aussi faciles grâce à la puissance de
                l'IA et la plateforme Robovision.
              </p>
              <Link to="/tools/tool-1">En savoir plus</Link>
            </div>
            <div className="tool-item-u">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/freshdesk-logo.svg"
                alt="AI Eszköz Freshdesk"
              />
              <h3>
                <b>Freshdesk</b>
              </h3>
              <p>Solution de service client basée sur l'IA.</p>
              <p>Offrez un service client sans effort avec Freshdesk</p>
              <Link to="/tools/tool-2">En savoir plus</Link>
            </div>
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Bard-AI-Logo.png"
                alt="AI Eszköz Bard AI"
              />
              <h3>
                <b>Bard AI</b>
              </h3>
              <p>Un outil d'IA qui contribue à augmenter la productivité.</p>
              <p>
                Bard est un service expérimental d'IA conversationnelle
                développé par Google, propulsé par son modèle linguistique pour
                les applications de dialogue (LaMDA). Il est conçu pour fournir
                des réponses fraîches et de haute qualité en puisant des
                informations sur le Web. Attributs du barde directs de longues
                citations à la page Web source et offre aux utilisateurs le
                possibilité de visualiser et éventuellement de cliquer sur
                l'original source via des URL ou des vignettes d'images pour
                vérification et plus encore exploration.
              </p>
              <Link to="/tools/tool-3">En savoir plus</Link>
            </div>
          </div>
        </section>
        {/* Hírlevél feliratkozás */}
        <section className="newsletter container">
          <h2>Abonnez-vous à notre newsletter !</h2>
          <p>
            Ne manquez pas les dernières tendances et innovations en matière
            d'IA.
          </p>
          <form>
            <input type="email" placeholder="Email address" />
            <button type="submit">Abonnement</button>
          </form>
        </section>
      </main>
    );
  }
}

export default HomeFr;
