import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import Config from "../Config.json";
import { Link, useNavigate } from "react-router-dom";

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const ContactEn = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Az EmailJS szolgáltatás ID a .env fájlból
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Az EmailJS sablon ID a .env fájlból
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID // A felhasználói azonosító a .env fájlból
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message successfully sent!");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          alert("An error occurred while sending the message.");
        }
      );
  };

  useEffect(() => {
    const mybutton = document.getElementById("TopBtn");
    const scrollFunction = () => {
      if (window.scrollY > 100) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    };
    const topFunction = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("scroll", scrollFunction);
    mybutton.addEventListener("click", topFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
      mybutton.removeEventListener("click", topFunction);
    };
  }, []);

  return (
    <main className="contact-main-content">
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href={CANONICAL}></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <button id="TopBtn" title="Go to top">
        &#10224;
      </button>
      <div className="w-100">
        <div className="contact-container">
          <h1 className="contact py-3 ps-3">Contact us</h1>
        </div>
        <div className="contact-content-container w-75">
          <p className="contact-margin-y1">
            Get in touch with us to share what AI topics you'd like to explore!
            Let us know if there’s a particular AI tool, aspect, or application
            you're curious about, or if there's any other topic where we can
            provide insights and support. We're here to help bring clarity to
            the world of AI.
          </p>
          <h2 className="contact-color">
            <b>Contact us directly by e-mail</b>
          </h2>
          <p>
            Email:{" "}
            <a
              className="contact-href"
              href="mailto:info@aiinsidelife.com?subject=Question%20regarding%20the%20everyday%20use%20of%20artificial%20intelligence&body=Dear%20AI%20Inside%20Life%20team,%0D%0A%0D%0AI%20am%20interested%20in%20the%20application%20of%20artificial%20intelligence%20solutions%20in%20everyday%20life.%20I%20would%20like%20to%20receive%20more%20information%20on%20the%20following%20topic:%0D%0A%0D%0A[Enter%20your%20questions%20or%20comments%20here]%0D%0A%0D%0ARegards,%0D%0A[Your%20name]"
              target="_top"
            >
              info@aiinsidelife.com
            </a>
          </p>
          <p>Facebook: facebook/aiinsidelife.com</p>
          <p>LinkedIn: [Insert Address]</p>
          <p>Twitter: [Insert Address]</p>

          {/* Form that sends email */}
          <form
            ref={form}
            onSubmit={sendEmail}
            className="contact-margin-y contact-color"
          >
            <h2>
              <b>Or send us a message via the following form</b>
            </h2>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputVezNev" className="form-label">
                Firstname <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="vezeteknev"
                placeholder="Firstname"
                required
              />
            </div>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputKeresztNev" className="form-label">
                Surname <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="keresztnev"
                placeholder="Surname"
                required
              />
            </div>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Enter email"
                required
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="comment">
                Comment: <span>*</span>
              </label>
              <textarea
                className="form-control"
                rows="5"
                name="comment"
                required
              ></textarea>
            </div>
            <div className="mt-4 mb-4 form-check">
              <h6>
                By pressing the Send button, I acknowledge that I have read I
                have understood and accept the Data Protection Notice, which
                based on which the site manages my data.
              </h6>
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                I agree that my personal data provided by Aiinsidelife.com -
                until my consent is withdrawn - for direct business purposes
                (information, public opinion or market research or other
                information).
              </label>
            </div>
            <button type="submit" className="btn btn-Bosch-primary">
              Send
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ContactEn;
