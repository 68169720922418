import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";
import Chatbox from "../components/Chatbox"; // Chatbox importálása

// HTML elemek beállítása
const TITLE = "Home | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const HomeHu = () => {
  // Google Adsense beállítások
  useEffect(() => {
    const adsScript = document.createElement("script");
    adsScript.async = true;
    adsScript.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    adsScript.setAttribute("data-ad-client", "ca-pub-YOUR-ADSENSE-ID"); // Cseréld le a saját Google Adsense ID-dra
    document.head.appendChild(adsScript);

    // Bootstrap Carousel újrainicializálása
    const bootstrapScript = document.createElement("script");
    bootstrapScript.src =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js";
    document.body.appendChild(bootstrapScript);

    // Carousel automatikus újraindítása navigáció után
    bootstrapScript.onload = () => {
      const carouselElement = document.querySelector("#carouselExampleDark");
      if (carouselElement) {
        const carousel = new window.bootstrap.Carousel(carouselElement, {
          interval: 3000,
          ride: "carousel",
        });
      }
    };

    // Az aloldal betöltésekor hívd meg ezt a funkciót
    updateMetaDescription("Your dynamic page description here.");

    return () => {
      // Cleanup: script eltávolítása
      document.body.removeChild(bootstrapScript);
    };
  }, []);
  function updateMetaDescription(content) {
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", content);
    } else {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = content;
      document.head.appendChild(metaDescription);
    }
  }
  return (
    <main className="main-content">
      <Helmet>
        <title>{TITLE}</title>
        <link rel="canonical" href={CANONICAL} />
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
        <meta
          name="description"
          content="Fedezd fel a legújabb mesterséges intelligencia (MI) trendeket, eszközöket és innovációkat. Blogunkban bemutatjuk az MI mindennapi használatát, az okos technológiáktól az MI alapú játékokig, valamint hasznos tippeket kínálunk az MI hatékony alkalmazásához."
        />
      </Helmet>

      {/* Hero szekció */}
      <section className="hero col-12">
        <h1>
          Fedezd fel, hogyan alakítja a mesterséges intelligencia a mindennapi
          életet.
        </h1>
      </section>
      {/* Cookie Consent Modal */}
      <div id="cookieModal" className="cookie-modal">
        <div className="cookie-modal-content">
          <h3>The site uses cookies</h3>
          <p>
            Our website uses cookies to improve the user experience. Please
            accept cookies for the full user experience.
          </p>
          <div className="cookie-buttons">
            <button id="acceptCookies" className="btn btn-AIinsideLife-primary">
              I accept it
            </button>
            <button id="rejectCookies" className="btn btn-secondary">
              I refuse
            </button>
          </div>
        </div>
      </div>
      {/* Tartalom és jobb oldali hirdetések két oszlopos elrendezésben */}
      <div className="container mt-4">
        <div className="row">
          {/* Bal oldali tartalom - cikkek */}
          <div className="col-lg-9">
            <section className="latest-posts">
              <h2>
                <b>Generatív mesterséges intelligencia az életben</b>
              </h2>
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner my-3">
                  <div className="carousel-item active" data-bs-interval="3000">
                    <a
                      href="https://link4.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./images/flux-tiktok.png"
                        className="d-block w-100"
                        alt="kjkj"
                      />
                    </a>
                    <div className="carousel-caption d-none d-md-block">
                      <h5>First slide label</h5>
                      <p>
                        Some representative placeholder content for the first
                        slide.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="3000">
                    <a
                      href="https://link4.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./images/AIHero.webp"
                        className="d-block w-100"
                        alt="pkpk"
                      />
                    </a>
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Second slide label</h5>
                      <p>
                        Some representative placeholder content for the second
                        slide.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="3000">
                    <a
                      href="https://link4.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./images/gptLogo.webp"
                        className="d-block w-100"
                        alt="..."
                      />
                    </a>

                    <div className="carousel-caption d-none d-md-block">
                      <h5>Third slide label</h5>
                      <p>
                        Some representative placeholder content for the third
                        slide.
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>

              <article className="post post-left row mb-4 py-3 shadow">
                <div className="col-3 image-container">
                  <img
                    className="img-fluid mt-2 mb-2"
                    src="../images/flux-tiktok.png"
                    alt="Bejegyzés kép"
                  />
                </div>
                <div className="col-9 content-container shadow-sm rounded">
                  <div className="heading-container">
                    <div className="row">
                      <div className="col-12">
                        <h3>AI Trendek 2024-ben</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h4>TikTok Notes, az Instagram új versenytársa:</h4>
                      </div>
                    </div>
                  </div>
                  <p>
                    A TikTok merész lépést tett azzal, hogy elindította a TikTok
                    Notes szolgáltatást, amely az Instagram riválisa a közösségi
                    média világában. A mesterséges intelligencia által vezérelt
                    algoritmusok erejével a TikTok célja a felhasználói élmény
                    javítása, új módot kínálva a gyors frissítések megosztására,
                    és megkérdőjelezi az Instagram dominanciáját a fotómegosztó
                    és a közösségi interakció terén...
                  </p>
                  <Link to="/Blogs/AITrendek/AITrendek">Tovább olvasom</Link>
                </div>
              </article>
              <article className="post post-right row mb-4 py-3 shadow">
                <div className="col-9">
                  <h3>Gépi tanulás egyszerűen</h3>
                  <h3>
                    Tanulj meg mindent a gépi tanulásról, egyszerűen és
                    érthetően.
                  </h3>

                  <p>
                    A gépi tanulás azért fontos, mert a vállalkozások számára a
                    az ügyfelek viselkedésének és az operatív üzletnek a
                    trendjei minták, valamint támogatja az új termékek
                    fejlesztését. Napjaink vezető vállalatai közül sok, mint a
                    Facebook, a Google, és az Uber a gépi tanulást központi
                    részévé teszik műveleteket.
                  </p>
                  <Link to="/Blogs/AITrendek">Tovább olvasom</Link>
                </div>
                <div className="col-3">
                  <img
                    className="img-fluid mt-2 mb-2"
                    src="../images/machine-learning.webp"
                    alt="Bejegyzés kép"
                  />
                </div>
              </article>
              <article className="post post-left row mb-4 py-3 shadow">
                <div className="col-3">
                  <img
                    className="img-fluid mt-2 mb-2"
                    src="../images/jasperai.png"
                    alt="Bejegyzés kép"
                  />
                </div>
                <div className="col-9">
                  <h3>AI eszközök vállalkozásoknak</h3>
                  <p>
                    A legjobb AI eszközök, amelyek segítenek növelni
                    vállalkozásod hatékonyságát.
                  </p>
                  <Link to="/Blogs/AITrendek">Tovább olvasom</Link>
                </div>
              </article>
            </section>
            {/* AI Eszközök */}
            <section className="tools container">
              <h2>Ajánlott AI eszközök</h2>
              <div className="tools-list">
                <div className="tool-item-p">
                  <img
                    className="img-fluid2 mt-2 mb-2 "
                    src="../images/Robovision-pink-logo-banner.jpg"
                    alt="AI Eszköz Robovision-pink-logo-banner.jpg"
                  />
                  <h3>
                    A mesterséges intelligencia által működtetett Computer
                    Vision Platform
                  </h3>
                  <p>Hatékony AI eszköz a képfelismeréshez.</p>
                  <p>
                    A Robovision egy vision AI platformot kínál, amely lefedi a
                    teljes AI-t életciklus. Racionalizálja a teljes fejlesztési
                    folyamatot, a mesterséges intelligencia megvalósítása és
                    adaptálása napjaink folyamatosan változó üzletágában
                    környezet. Alkalmazások készítése összetett számítógépes
                    látáshoz a kihívások még soha nem voltak ilyenek az AI
                    erejének köszönhetően a Robovision Platform.
                  </p>
                  <Link to="/tools/tool-1">Tudj meg többet</Link>
                </div>
                <div className="tool-item-u">
                  <img
                    className="img-fluid2 mt-2 mb-2 "
                    src="../images/freshdesk-logo.svg"
                    alt="AI Eszköz Freshdesk"
                  />
                  <h3>
                    <b>Freshdesk</b>
                  </h3>
                  <p>AI alapú ügyfélszolgálat megoldás.</p>
                  <p>Könnyű ügyfélszolgálatot nyújt a Freshdesk segítségével</p>
                  <Link to="/tools/tool-2">Tudj meg többet</Link>
                </div>
                <div className="tool-item-p">
                  <img
                    className="img-fluid2 mt-2 mb-2 "
                    src="../images/Bard-AI-Logo.png"
                    alt="AI Eszköz Bard AI"
                  />
                  <h3>
                    <b>Bard AI</b>Bard AI
                  </h3>
                  <p>AI eszköz, amely segít a termelékenység növelésében.</p>
                  <p>
                    A Bard egy kísérleti, párbeszédes AI-szolgáltatás, amelyet a
                    cég fejlesztett ki A Google, a párbeszédes alkalmazások
                    nyelvi modellje alapján (LaMDA). Úgy tervezték, hogy kiváló
                    minőségű, friss válaszokat adjon információk lehívásával a
                    webről. Bard attribútumok közvetlen hosszú idézeteket a
                    forráswebhelyre, és felajánlja a felhasználóknak a az
                    eredeti megtekintésének és esetlegesen átkattintásának
                    képessége forrás URL-eken vagy miniatűrökön keresztül az
                    ellenőrzéshez és további információkhoz kutatás.
                  </p>
                  <Link to="/tools/tool-3">Tudj meg többet</Link>
                </div>
              </div>
            </section>
            {/* Hírlevél feliratkozás */}
            <section className="newsletter container py-3 my-3">
              <h2>Iratkozz fel a hírlevelünkre!</h2>
              <p>
                Ne maradj le az AI legfrissebb trendjeiről és újdonságairól.
              </p>
              <form>
                <input type="email" placeholder="Email cím" />
                <button type="submit">Feliratkozás</button>
              </form>
            </section>
          </div>

          {/* Jobb oldali panel hirdetésekkel és chatbot-al */}
          <div className="col-lg-3">
            {/* Banner hirdetések */}
            <section className="banners mb-4">
              <h3>Hirdetések</h3>
              <a
                href="https://www.origo.hu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid banner mt-2 mb-2"
                  src="../images/gptLogo.webp"
                  alt="Origo hirdetés"
                />
              </a>
              <a
                href="https://www.salgosoft.hu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid banner mt-2 mb-2"
                  src="../images/HeroLogo.webp"
                  alt="Salgosoft hirdetés"
                />
              </a>
            </section>
            {/* AI Dungeon beágyazása */}
            <section className="ai-dungeon-container">
              <h2>Játssz az AI Dungeon játékkal!</h2>
              <iframe
                src="https://play.aidungeon.io/"
                title="AI Dungeon"
                width="100%"
                height="600px"
                style={{ border: "none" }}
              ></iframe>
            </section>
            <section className="news">
              <h2>ML (Machine Learning) training</h2>
            </section>
            {/* Google Adsense hirdetés */}
            <section className="adsense mb-4">
              <h3>Google Hirdetés</h3>
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-YOUR-ADSENSE-ID" // Google Adsense azonosító
                data-ad-slot="YOUR-AD-SLOT-ID" // Hirdetési slot ID
                data-ad-format="auto"
              ></ins>
            </section>

            {/* Beépített ChatGPT Chatbox */}
            <section className="chatbox">
              <h3>ChatGPT Chatbox</h3>
              {/* Chatbox hozzáadása */}
              <Chatbox />
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeHu;
