import React from "react";
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="footer-content">
          <nav className="footer-nav">
            {/*<Link to="/">Kezdőlap</Link>
            <Link to="/privacy">Adatvédelem</Link>
            <Link to="/terms">Felhasználási feltételek</Link>
            <Link to="/contact">Kapcsolat</Link>*/}
          </nav>

          <div className="footer-contact ">
            <p className="mx-3 my-4 pt-3 copyright">Follow us:</p>

            <div className="social-media ">
              <div>
                <ul>
                  <li>
                    <a href="https://facebook.com " target="blank">
                      <span></span>
                      <span></span>
                      <span></span>

                      <span class="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com" target="blank">
                      <span></span>
                      <span></span>
                      <span></span>

                      <span class="fa fa-twitter" target="blank"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com" target="blank">
                      <span></span>
                      <span></span>
                      <span></span>

                      <span class="fa fa-instagram" target="blank"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com" target="blank">
                      <span></span>
                      <span></span>
                      <span></span>

                      <span class="fa fa-linkedin" target="blank"></span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@aiinsidelife.com?subject=My%20Message&body=You%20are%20invited%20to%20a%20big%20summer%20party!">
                      <span></span>
                      <span></span>
                      <span></span>

                      <span class="fa fa-envelope"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="copyright mt-3 mx-3 pt-2">
            © 2024 AI Inside Lfe. All rights reserved.
          </p>
        </div>
        <div>
          <NavLink className="copyright mx-3" to="/PrivacyPolicy">
            Privacy Policy
          </NavLink>
        </div>
      </footer>
    );
  }
}

export default Footer;
