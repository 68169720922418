import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import Config from "../Config.json";
import { Link, useNavigate } from "react-router-dom";

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const ContactFr = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Az EmailJS szolgáltatás ID a .env fájlból
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Az EmailJS sablon ID a .env fájlból
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID // A felhasználói azonosító a .env fájlból
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message envoyé avec succès !");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          alert("Une erreur s'est produite lors de l'envoi du message.");
        }
      );
  };

  return (
    <main className="contact-main-content">
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href={CANONICAL}></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <div className="w-100">
        <div className="contact-container">
          <h1 className="contact py-3 ps-3">Contact us</h1>
        </div>
        <div className="contact-content-container w-75">
          <p className="contact-margin-y1">
            Contactez-nous pour partager les sujets sur l'IA que vous aimeriez
            explorer ! Faites-nous savoir s'il existe un outil, un aspect ou une
            application d'IA particulier vous êtes curieux, ou s'il y a un autre
            sujet sur lequel nous pouvons fournir des informations et un
            soutien. Nous sommes là pour vous aider à apporter de la clarté le
            monde de l'IA.
          </p>
          <h2 className="contact-color">
            <b>Contactez-nous directement par e-mail</b>
          </h2>
          <p>
            Email:{" "}
            <a
              className="contact-href"
              href="mailto:info@aiinsidelife.com?subject=Question%20regarding%20the%20everyday%20use%20of%20artificial%20intelligence&body=Dear%20AI%20Inside%20Life%20team,%0D%0A%0D%0AI%20am%20interested%20in%20the%20application%20of%20artificial%20intelligence%20solutions%20in%20everyday%20life.%20I%20would%20like%20to%20receive%20more%20information%20on%20the%20following%20topic:%0D%0A%0D%0A[Enter%20your%20questions%20or%20comments%20here]%0D%0A%0D%0ARegards,%0D%0A[Your%20name]"
              target="_top"
            >
              info@aiinsidelife.com
            </a>
          </p>
          <p>Facebook: facebook/aiinsidelife.com</p>
          <p>LinkedIn: [Insert Address]</p>
          <p>Twitter: [Insert Address]</p>

          {/* Form that sends email */}
          <form
            ref={form}
            onSubmit={sendEmail}
            className="contact-margin-y contact-color"
          >
            <h2>
              <b>Ou envoyez-nous un message via le formulaire suivant</b>
            </h2>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputVezNev" className="form-label">
                Prénom <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="vezeteknev"
                placeholder="Prénom"
                required
              />
            </div>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputKeresztNev" className="form-label">
                Nom de famille <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="keresztnev"
                placeholder="Nom de famille"
                required
              />
            </div>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Adresse email <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Adresse email"
                required
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="comment">
                Commentaire: <span>*</span>
              </label>
              <textarea
                className="form-control"
                rows="5"
                name="comment"
                required
              ></textarea>
            </div>
            <div className="mt-4 mb-4 form-check">
              <h6>
                En appuyant sur le bouton Envoyer, je reconnais avoir lu avoir
                compris et accepté l'avis de protection des données, qui sur la
                base duquel le site gère mes données.
              </h6>
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                J'accepte que mes données personnelles fournies par
                Aiinsidelife.com - jusqu'à ce que mon consentement soit retiré -
                à des fins commerciales directes (information, opinion publique
                ou étude de marché ou autre information).
              </label>
            </div>
            <button type="submit" className="btn btn-Bosch-primary">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ContactFr;
