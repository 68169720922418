import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Markdown from "markdown-to-jsx";

const Article = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation
  const { articleId } = location.state; // Get articleId from state
  const [articleContent, setArticleContent] = useState("");

  useEffect(() => {
    const fetchArticleContent = async () => {
      try {
        const response = await axios.get(`/Markdown/${articleId}.md`);
        setArticleContent(response.data);
      } catch (error) {
        console.error("Error fetching the markdown content:", error);
      }
    };

    fetchArticleContent();
  }, [articleId]);

  // Function to navigate back to the previous page
  const handleGoBack = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className="main-content article-content px-4 md-3">
      <Markdown>{articleContent}</Markdown> {/* Render the markdown content */}
      {/* Back Button */}
      <button onClick={handleGoBack} className="btn btn-secondary my-3">
        Vissza
      </button>
    </div>
  );
};

export default Article;
