import React from "react";

import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";
import HomeEn from "../pages/HomeEn";

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

class PageNotFound extends React.Component {
  render() {
    return (
      <main>
        <Helmet>
          <title>{TITLE}</title>
          <Link rel="canonical" href="{CANONICAL}"></Link>
          <meta name="description" content={DESC} />
          <meta name="theme-color" content={Config.THEME_COLOR} />
        </Helmet>
        <div className="err404">
          <h2>A keresett oldal nem található!</h2>
          <p>Kollégáink dolgoznak a probléma megoldásán................</p>
          <img id="err404" src="../images/r_67601_KaFWy.gif" alt="dfdf"></img>

          <p>
            Vissza a főoldalra <Link to={"HomeEn"}>Vissza</Link>
          </p>
        </div>
      </main>
    );
  }
}

export default PageNotFound;
