import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import Config from "../Config.json";
import { Link, useNavigate } from "react-router-dom";

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const ContactDe = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Az EmailJS szolgáltatás ID a .env fájlból
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Az EmailJS sablon ID a .env fájlból
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID // A felhasználói azonosító a .env fájlból
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Nachricht erfolgreich gesendet!");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          alert("Beim Senden der Nachricht ist ein Fehler aufgetreten.");
        }
      );
  };

  return (
    <main className="contact-main-content">
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href={CANONICAL}></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <div className="w-100">
        <div className="contact-container">
          <h1 className="contact py-3 ps-3">Contact us</h1>
        </div>
        <div className="contact-content-container w-75">
          <p className="contact-margin-y1">
            Nehmen Sie Kontakt mit uns auf, um uns mitzuteilen, welche KI-Themen
            Sie erforschen möchten! Teilen Sie uns mit, ob es ein bestimmtes
            KI-Tool, einen bestimmten Aspekt oder eine bestimmte Anwendung gibt
            Sie sind neugierig, oder es gibt ein anderes Thema, bei dem wir uns
            melden können bieten Einblicke und Unterstützung. Wir sind hier, um
            Klarheit zu schaffen die Welt der KI.
          </p>
          <h2 className="contact-color">
            <b>Kontaktieren Sie uns direkt per E-Mail</b>
          </h2>
          <p>
            Email:{" "}
            <a
              className="contact-href"
              href="mailto:info@aiinsidelife.com?subject=Question%20regarding%20the%20everyday%20use%20of%20artificial%20intelligence&body=Dear%20AI%20Inside%20Life%20team,%0D%0A%0D%0AI%20am%20interested%20in%20the%20application%20of%20artificial%20intelligence%20solutions%20in%20everyday%20life.%20I%20would%20like%20to%20receive%20more%20information%20on%20the%20following%20topic:%0D%0A%0D%0A[Enter%20your%20questions%20or%20comments%20here]%0D%0A%0D%0ARegards,%0D%0A[Your%20name]"
              target="_top"
            >
              info@aiinsidelife.com
            </a>
          </p>
          <p>Facebook: facebook/aiinsidelife.com</p>
          <p>LinkedIn: [Insert Address]</p>
          <p>Twitter: [Insert Address]</p>

          {/* Form that sends email */}
          <form
            ref={form}
            onSubmit={sendEmail}
            className="contact-margin-y contact-color"
          >
            <h2>
              <b>
                Oder senden Sie uns eine Nachricht über das folgende Formular
              </b>
            </h2>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputVezNev" className="form-label">
                Vorname <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="vezeteknev"
                placeholder="Vorname"
                required
              />
            </div>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputKeresztNev" className="form-label">
                Nachname <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="keresztnev"
                placeholder="Nachname"
                required
              />
            </div>
            <div className="mt-4 mb-4">
              <label htmlFor="exampleInputEmail1" className="form-label">
                E-Mail-Adresse <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="E-Mail-Adresse"
                required
              />
              <div id="emailHelp" className="form-text">
                Wir geben Ihre E-Mail-Adresse niemals an Dritte weiter.
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="comment">
                Comment: <span>*</span>
              </label>
              <textarea
                className="form-control"
                rows="5"
                name="comment"
                required
              ></textarea>
            </div>
            <div className="mt-4 mb-4 form-check">
              <h6>
                Durch Klicken auf die Schaltfläche „Senden“ bestätige ich, dass
                ich I gelesen habe Sie haben die Datenschutzhinweise verstanden
                und akzeptieren diese auf deren Grundlage die Website meine
                Daten verwaltet.
              </h6>
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Ich bin damit einverstanden, dass meine von Aiinsidelife.com
                bereitgestellten persönlichen Daten - bis zum Widerruf meiner
                Einwilligung – für unmittelbare geschäftliche Zwecke
                (Informationen, öffentliche Meinung oder Marktforschung oder
                andere Information).
              </label>
            </div>
            <button type="submit" className="btn btn-Bosch-primary">
              Senden
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ContactDe;
