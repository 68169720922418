import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import aiArticles from "../Articles/AITrendek.json"; // JSON fájl importálása
import YoutubeEmbed from "../Blogs/YoutubeEmbeded";
const AiTrendsPost = () => {
  const { articleId } = useParams(); // Az URL-ből vesszük a cikk azonosítót
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const selectedArticle = aiArticles[articleId]; // Cikk keresése az azonosító alapján
    if (selectedArticle) {
      setArticle(selectedArticle);
    } else {
      setArticle(null); // Ha nem található a cikk
    }
  }, [articleId]);

  if (!article) {
    return <p>A cikk nem található.</p>; // Ha a cikk nem található, hibát jelenít meg
  }

  return (
    <div className="blog-post container">
      <h1>{article.title}</h1>
      <h2>{article.subtitle}</h2>
      <p>{article.content1}</p>
      <div className="App">
        <h1>Nézd meg róla a videót!</h1>
        <YoutubeEmbed embedId="1MrxsrnThj0" />
      </div>
      <p>{article.content2}</p>
      <p>{article.content3}</p>
    </div>
  );
};

export default AiTrendsPost;
