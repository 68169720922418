import React from "react";
import { Link } from "react-router-dom";

const LinkOpenError = ({ errorMessage, errorDetails }) => {
  return (
    <div className="error-page">
      <h1>Oops! Something went wrong.</h1>
      <p>
        {errorMessage ||
          "We couldn't connect to the server or find the page you were looking for."}
      </p>
      {errorDetails && <p className="error-details">{errorDetails}</p>}
      <p>Please try the following:</p>
      <ul>
        <li>Check your internet connection.</li>
        <li>Ensure that the address is correct.</li>
        <li>Try refreshing the page or come back later.</li>
      </ul>
      <Link to="/" className="back-home">
        Go back to the homepage
      </Link>
    </div>
  );
};

export default LinkOpenError;
