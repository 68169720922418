import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { useNavigate } from "react-router-dom";
import articlesData from "../Articles/ArticlesEn.json";

const BlogEn = ({ article }) => {
  const TITLE = "DBC | " + Config.SITE_TITLE;
  const DESC = "Create a React App from an HTML Website.";
  const CANONICAL = Config.SITE_DOMAIN + "/";

  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("Novelty");
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [counters, setCounters] = useState({});

  const filterArticlesByCategory = (category) => {
    const filtered = articlesData.filter((article) =>
      article.category.includes(category)
    );
    setFilteredArticles(filtered);
  };

  const calculateElapsedTime = (timeString) => {
    const publicationDate = new Date(timeString);
    const now = new Date();
    const diffInMs = now - publicationDate;

    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return `${diffInDays} days ago`;
    }
  };

  useEffect(() => {
    const storedCounters = localStorage.getItem("articleCounters");
    if (storedCounters) {
      setCounters(JSON.parse(storedCounters));
    } else {
      const initialCounters = {};
      filteredArticles.forEach((article) => {
        initialCounters[article.ID] = { likes: 0, dislikes: 0 };
      });
      setCounters(initialCounters);
    }
  }, [filteredArticles]);

  useEffect(() => {
    if (Object.keys(counters).length > 0) {
      localStorage.setItem("articleCounters", JSON.stringify(counters));
    }
  }, [counters]);

  const handleLike = (articleId) => {
    setCounters((prevCounters) => ({
      ...prevCounters,
      [articleId]: {
        likes: (prevCounters[articleId]?.likes || 0) + 1,
        dislikes: prevCounters[articleId]?.dislikes || 0,
      },
    }));
  };

  const handleDislike = (articleId) => {
    setCounters((prevCounters) => ({
      ...prevCounters,
      [articleId]: {
        likes: prevCounters[articleId]?.likes || 0,
        dislikes: (prevCounters[articleId]?.dislikes || 0) + 1,
      },
    }));
  };

  const handleCategoryChange = (category, categoryName) => {
    setSelectedCategory(categoryName);
    filterArticlesByCategory(category);
  };

  const handleReadMore = (articleId) => {
    navigate("/article", { state: { articleId } });
  };

  useEffect(() => {
    filterArticlesByCategory("New");
  }, []);

  return (
    <main className="main-content">
      <Helmet>
        <title>{TITLE}</title>
        <link rel="canonical" href={CANONICAL}></link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <button id="TopBtn" title="Go to top">
        &#10224;
      </button>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 d-flex flex-wrap justify-content-center">
            <button
              className="btn btn-AIinsideLife-primary m-2"
              onClick={() => handleCategoryChange("Trend", "AI Trends")}
            >
              AI Trends
            </button>
            <button
              className="btn btn-AIinsideLife-primary m-2"
              onClick={() => handleCategoryChange("New", "Novelty")}
            >
              Novelty
            </button>
            <button
              className="btn btn-AIinsideLife-primary m-2"
              onClick={() =>
                handleCategoryChange("Recomment", "Recommended AI tools")
              }
            >
              Certified AI tools
            </button>
            <button
              className="btn btn-AIinsideLife-primary m-2"
              onClick={() =>
                handleCategoryChange("Machine Learning", "Machine Learning")
              }
            >
              Machine Learning
            </button>
            <button
              className="btn btn-AIinsideLife-primary m-2"
              onClick={() => handleCategoryChange("Game", "AI Games")}
            >
              AI Games
            </button>
          </div>
        </div>
        <div className="row">
          <h1 className="text-center">{selectedCategory}</h1>
        </div>
        <div className="row g-3">
          {filteredArticles.map((article) => (
            <div className="col-sm-12 col-md-6 col-lg-4" key={article.ID}>
              <div className="card h-100">
                <img
                  src={article.img}
                  className="card-img-top"
                  alt={article.alt}
                />
                <div className="card-body">
                  <h5 className="card-title">{article.title}</h5>
                  <p className="card-text text-truncate">{article.teaser}</p>
                  <p className="text-muted">
                    Published: {calculateElapsedTime(article.time)}
                  </p>
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => handleLike(article.ID)}
                    >
                      👍 {counters[article.ID]?.likes || 0}
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => handleDislike(article.ID)}
                    >
                      👎 {counters[article.ID]?.dislikes || 0}
                    </button>
                  </div>
                  <button
                    onClick={() => handleReadMore(article.ID)}
                    className="btn btn-AIinsideLife-primary w-100 mt-2"
                  >
                    Read More...
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default BlogEn;
