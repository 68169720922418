import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import Config from "../Config.json";
import { Link, useNavigate } from "react-router-dom";

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const PrivacyPolicy = () => {
  return (
    <main className="contact-main-content">
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href={CANONICAL}></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <div className="w-100">
        <div className="contact-container">
          <h1 className="contact py-3 ps-3">Privace Policy</h1>
        </div>
        <div className="contact-content-container w-75">
          <h1 className="contact-color contact-margin-y">
            Privacy Policy for Newsletter Subscription
          </h1>
          <h5>Effective Date: 25.10.2024</h5>
          <h2 className="contact-color contact-margin-y2">Introduction</h2>
          <p>
            At AIIsideLife.com, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, and protect the personal data
            you provide when subscribing to our newsletter. By subscribing, you
            agree to the terms set forth in this policy.
          </p>
          <h2 className="contact-color contact-margin-y2">Data Controller</h2>
          <p>
            The data controller responsible for your personal data is
            AIIsideLife.com. Should you have any questions or concerns regarding
            this Privacy Policy, you can contact us at:
          </p>
          <p>Email: [Insert Email]</p>
          <p>Phone: [Insert Phone Number]</p>
          <p>Address: [Insert Address]</p>
          <h2 className="contact-color contact-margin-y2">
            Information We Collect
          </h2>
          <p>
            When you subscribe to our newsletter, we collect the following
            personal information:
          </p>
          <ul>
            <li>
              Send periodic newsletters with updates, promotions, and special
              offers.
            </li>

            <li>Personalize our communication based on your preferences.</li>
            <li>
              Provide customer support or respond to any inquiries you might
              have.
            </li>
            <li>
              Occasionally, with your explicit consent, send promotional text
              messages to your phone.
            </li>
          </ul>
          <h2 className="contact-color contact-margin-y2">
            Legal Basis for Processing
          </h2>
          <p>
            We process your personal data based on your consent when you
            voluntarily provide your information for the purpose of subscribing
            to our newsletter. You may withdraw your consent at any time by
            unsubscribing from the newsletter or contacting us directly.
          </p>
          <h2 className="contact-color contact-margin-y2">Data Retention</h2>
          <p>
            We retain your personal information for as long as you are
            subscribed to our newsletter. If you choose to unsubscribe, we will
            remove your personal data from our mailing list within a reasonable
            time, unless we are required to retain certain data for legal or
            regulatory reasons.
          </p>
          <h2 className="contact-color contact-margin-y2">Your Rights</h2>
          <p>
            Under applicable data protection laws, you have the following rights
            regarding your personal data:
          </p>
          <ul>
            <li>
              <b>Right to Access:</b> You can request a copy of the personal
              information we hold about you.
            </li>

            <li>
              <b>Right to Rectification:</b> You can ask us to update or correct
              any inaccurate data.
            </li>
            <li>
              <b>Right to Erasure:</b> You can request the deletion of your
              personal data.
            </li>
            <li>
              <b>Right to Restrict Processing:</b> You can ask us to limit the
              use of your personal data.
            </li>
            <li>
              <b>Right to Object:</b> You can object to the processing of your
              data for marketing purposes at any time.
            </li>
            <li>
              <b>Right to Data Portability:</b> You can request to receive your
              personal data in a structured, commonly used format.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us using the contact
            information provided above.
          </p>
          <h2 className="contact-color contact-margin-y2">Data Security</h2>
          <p>
            We take the security of your personal information seriously. We
            implement appropriate technical and organizational measures to
            protect your data against unauthorized access, loss, or misuse.
          </p>
          <h2 className="contact-color contact-margin-y2">
            Third-Party Sharing
          </h2>
          <p>
            We do not sell or rent your personal data to third parties. However,
            we may share your information with trusted service providers who
            assist us in delivering the newsletter or operating our website,
            subject to strict confidentiality obligations.
          </p>
          <h2 className="contact-color contact-margin-y2">
            Changes to This Privacy Policy
          </h2>
          <p>
            We may update this Privacy Policy from time to time. If we make
            significant changes, we will notify you via email or by posting a
            notice on our website. Please review this page periodically for the
            latest information on our privacy practices.
          </p>
          <h2 className="contact-color contact-margin-y2">Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            how we handle your personal data, please contact us at:
          </p>
          <p>Email: [Insert Email]</p>
          <p>Phone: [Insert Phone Number]</p>
          <p>Address: [Insert Address]</p>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
