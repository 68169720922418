import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

import AOS from "aos";
import "aos/dist/aos.css";
import { createContext, useContext } from "react";

const TITLE = "Home | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";

const HomeEn = () => {
  const formRef = useRef();
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("hidden");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Google Adsense és Bootstrap Carousel script
    const adsScript = document.createElement("script");
    adsScript.async = true;
    adsScript.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    adsScript.setAttribute("data-ad-client", "ca-pub-YOUR-ADSENSE-ID");
    document.head.appendChild(adsScript);

    const bootstrapScript = document.createElement("script");
    bootstrapScript.src =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js";
    document.body.appendChild(bootstrapScript);

    bootstrapScript.onload = () => {
      const carouselElement = document.querySelector("#carouselExampleDark");
      if (carouselElement) {
        new window.bootstrap.Carousel(carouselElement, {
          interval: 5000,
          ride: "carousel",
        });
      }
    };

    AOS.init({
      duration: 1200,
      once: true,
    });

    // Az eseménykezelők beállítása az elem létezése esetén
    const mybutton = document.getElementById("TopBtn");
    if (mybutton) {
      const scrollFunction = () => {
        if (window.scrollY > 100) {
          mybutton.style.display = "block";
        } else {
          mybutton.style.display = "none";
        }
      };

      const topFunction = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

      window.addEventListener("scroll", scrollFunction);
      mybutton.addEventListener("click", topFunction);

      return () => {
        window.removeEventListener("scroll", scrollFunction);
        mybutton.removeEventListener("click", topFunction);
        document.body.removeChild(bootstrapScript);
      };
    } else {
      console.error("A TopBtn gomb nem található.");
    }
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          setMessage("Köszönjük, hogy feliratkoztál!");
          setMessageClass("success"); // CSS osztály a siker üzenethez
          setEmail(""); // Tisztítsd meg az input mezőt
        },
        (error) => {
          setMessage("Hiba történt a feliratkozás során.");
          setMessageClass("error"); // CSS osztály a hiba üzenethez
        }
      );
  };

  useEffect(() => {
    // Betöltjük a particles.js-t dinamikusan
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
    script.async = true;
    script.onload = () => {
      // Ha betöltődött a particles.js, meghívjuk a particlesJS funkciót
      if (window.particlesJS) {
        window.particlesJS("particles-js", {
          particles: {
            number: { value: 100 },
            size: { value: 3 },
            move: { speed: 1 },
            color: { value: "#ffffff" },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: true, mode: "repulse" },
              onclick: { enable: true, mode: "push" },
            },
          },
        });
      }
    };

    document.body.appendChild(script);
  }, []);

  return (
    <main className="main-content">
      <Helmet>
        <title>{TITLE}</title>
        {/* Link rel="canonical" href="{CANONICAL}" - ez nem fog működni a `{}` miatt */}
        <link rel="canonical" href={Config.SITE_DOMAIN} />
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
        <meta
          name="description"
          content="Explore the latest trends, tools, and innovations in Artificial Intelligence (AI). Our blog covers AI in everyday life, from smart technologies to AI-driven games and useful tips for using AI effectively."
        />
      </Helmet>
      {/* Hero szekció */}
      <section className="hero col-12 col-sm-12 col-md-12 col-lg-12 ">
        <h1>Discover how artificial intelligence is shaping everyday life.</h1>
      </section>
      {/* Cookie Consent Modal */}
      <div id="cookieModal" className="cookie-modal">
        <div className="cookie-modal-content">
          <h3>The site uses cookies</h3>
          <p>
            Our website uses cookies to improve the user experience. Please
            accept cookies for the full user experience.
          </p>
          <div className="cookie-buttons">
            <button id="acceptCookies" className="btn btn-primary">
              I accept it
            </button>
            <button id="rejectCookies" className="btn btn-secondary">
              I refuse
            </button>
          </div>
        </div>
      </div>
      <button id="TopBtn" title="Go to top">
        &#10224;
      </button>

      {/* Tartalom és jobb oldali hirdetések két oszlopos elrendezésben */}
      <div className="container mt-4">
        <div id="particles-js"></div>
        <div className="row">
          {/* Bal oldali tartalom - cikkek */}
          <div className="col-lg-9">
            <section className="latest-posts">
              <h2>
                <b>Generative AI Inside Life</b>
              </h2>
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner my-3">
                  <div className="carousel-item active" data-bs-interval="5000">
                    <a
                      href="LearningEn/#ML03"
                      //target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./articleimages/carouselImages1.png"
                        className="d-block w-100 lazy-load"
                        alt="kjkj"
                        loading="lazy"
                      />
                    </a>
                    <div className="carousel-caption d-none d-md-block crscolor1 ">
                      <h3>Driving into the Future</h3>
                      <h4>Machine Learning in Self-Driving Cars Explained</h4>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="5000">
                    <a href="LearningEn/#ML07">
                      <img
                        src="./articleimages/carouselImages2.png"
                        className="d-block w-100 lazy-load"
                        alt="pkpk"
                        loading="lazy"
                      />
                    </a>
                    <div className="carousel-caption d-none  d-md-block crscolor2">
                      <h3>Revolutionizing Healthcare</h3>
                      <h4>The Power of Machine Learning.</h4>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="5000">
                    <a
                      href="https://lightning.ai/?utm_term=ai%20development&utm_campaign=General+platform&utm_source=adwords&utm_medium=ppc&hsa_acc=1332059986&hsa_cam=21385925027&hsa_grp=164148004792&hsa_ad=702882092130&hsa_src=g&hsa_tgt=kwd-296920401310&hsa_kw=ai%20development&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=EAIaIQobChMI6oqi8-ONigMV25aDBx3TcwcIEAAYASAAEgJTnvD_BwE"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="../articleimages/carouselImages3.png"
                        className="d-block w-100 lazy-load"
                        alt="..."
                        loading="lazy"
                      />
                    </a>

                    <div className="carousel-caption d-none d-md-block crscolor3">
                      <h3>Turn ideas into AI products, Lightning fast.</h3>
                      <h4>
                        Easily build AI products with all full and low code
                        tools in one place. Access GPUs, train models, deploy
                        and more with zero setup.
                      </h4>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              <article
                id="ai-trends"
                className="post post-left row mb-4 py-3 shadow"
              >
                <div className="col-3 image-container">
                  <img
                    className="img-fluid mt-2 mb-2 lazy-load"
                    src="../images/flux-tiktok.png"
                    alt="Bejegyzés kép"
                    loading="lazy"
                  />
                </div>
                <div className="col-9 content-container shadow-sm rounded">
                  <div className="heading-container">
                    <div className="row">
                      <div className="col-12">
                        <h3>AI Trends in 2024</h3>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-12">
                        <h4>TikTok Notes, Instagram's New Competitor:</h4>
                      </div>
                    </div>
                  </div>
                  <p>
                    TikTok has made a bold move by launching TikTok Notes, a
                    feature set to rival Instagram in the social media
                    landscape. With the power of AI-driven algorithms, TikTok
                    aims to enhance user experience, offering a new way to share
                    quick updates and challenge Instagram's dominance in the
                    photo-sharing and social interaction space...
                  </p>
                  <p>
                    <Link to="/Aitools/#AIT01">Read more about AI Trends</Link>
                  </p>
                </div>
              </article>
              <article className="post post-right row mb-4 py-3 shadow">
                <div className="col-9">
                  <h3>Machine learning made easy</h3>
                  <h3>
                    Learn everything about machine learning in a simple and
                    understandable way.
                  </h3>

                  <p>
                    Machine learning is important because it gives enterprises a
                    view of trends in customer behavior and operational business
                    patterns, as well as supports the development of new
                    products. Many of today's leading companies, such as
                    Facebook, Google, and Uber, make machine learning a central
                    part of their operations.
                  </p>
                  <Link to="http://localhost:3000/Aitools">
                    Read more about AI Trends
                  </Link>
                </div>
                <div className="col-3">
                  <img
                    className="img-fluid mt-2 mb-2 lazy-load"
                    src="../images/machine-learning.webp"
                    alt="Bejegyzés kép"
                    loading="lazy"
                  />
                </div>
              </article>
              <article className="post post-left row mb-4 py-3 shadow">
                <div className="col-3">
                  <img
                    className="img-fluid mt-2 mb-2 lazy-load"
                    src="../images/jasperai.png"
                    alt="Bejegyzés kép"
                    loading="lazy"
                  />
                </div>
                <div className="col-9">
                  <h3>AI tools for businesses</h3>
                  <p>
                    The best AI tools to help grow your business efficiency.
                  </p>
                  <p>
                    Many people know Jasper as the best AI writing assistant
                    that leads the market with its impressive features and
                    quality. First you need to provide initial words that Jasper
                    parses, before bringing phrases, paragraphs or documents
                    created based on subject matter and tone. Less than 1,500
                    minutes can write a 15-word article under.
                  </p>
                  <Link to="/Blogs/AITrendek">Read more about AI Trends</Link>
                </div>
              </article>
            </section>
            {/* AI Eszközök */}
            <section className=" container">
              <h2>
                <b>Recommended AI tools</b>
              </h2>
              <div className="tools-list">
                <div className="tool-item-p">
                  <img
                    className="img-fluid2 mt-2 mb-2 lazy-load"
                    src="../images/Robovision-pink-logo-banner.jpg"
                    alt="AI Eszköz Robovision-pink-logo-banner.jpg"
                    loading="lazy"
                  />
                  <h3>Computer Vision Platform Powered By AI.</h3>
                  <p>Powerful AI tool for image recognition.</p>
                  <p>
                    Robovision offers a vision AI platform that covers the full
                    AI life cycle. Streamline the entire process of developing,
                    implementing and adapting AI in today’s ever-changing
                    business environment. Creating applications for complex
                    computer vision challenges has never been easier thanks to
                    the power of AI and the Robovision Platform.
                  </p>
                  <Link to="/Aitools/#AIT05">Find out more</Link>
                </div>
                <div className="tool-item-u">
                  <img
                    className="img-fluid2 mt-2 mb-2 lazy-load"
                    src="../images/freshdesk-logo.svg"
                    alt="AI Eszköz Freshdesk"
                    loading="lazy"
                  />
                  <h3>
                    <b>Freshdesk</b>
                  </h3>
                  <p>AI-based customer service solution.</p>
                  <p>Deliver effortless customer service with Freshdesk</p>
                  <Link to="/Aitools/#AIT02">Find out more</Link>
                </div>
                <div className="tool-item-p mb-3">
                  <img
                    className="img-fluid2 mt-2 mb-2 lazy-load"
                    src="../images/Bard-AI-Logo.png"
                    alt="AI Eszköz Bard AI"
                    loading="lazy"
                  />
                  <h3>
                    <b>Bard AI</b>Bard AI
                  </h3>
                  <p>An AI tool that helps increase productivity.</p>
                  <p>
                    Bard is an experimental conversational AI service developed
                    by Google, powered by its Language Model for Dialogue
                    Applications (LaMDA). It’s designed to provide high-quality,
                    fresh responses by drawing information from the web. Bard
                    attributes direct lengthy quotes to the source webpage and
                    offers users the ability to view and potentially click
                    through to the original source via URLs or image thumbnails
                    for verification and further exploration.
                  </p>
                  <Link to="/BlogEn/#REC01">Find out more</Link>
                </div>
              </div>
            </section>
          </div>

          {/* Jobb oldali panel hirdetésekkel és chatbot-al */}
          <div className="col-lg-3">
            {/* Banner hirdetések */}
            {/*<section className="banners mb-4">
              <h3>Hirdetések</h3>
              <a
                href="https://www.origo.hu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid banner mt-2 mb-2"
                  src="../images/gptLogo.webp"
                  alt="Origo hirdetés"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.salgosoft.hu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid banner mt-2 mb-2"
                  src="../images/HeroLogo.webp"
                  alt="Salgosoft hirdetés"
                  loading="lazy"
                />
              </a>
            </section> */}
            {/* AI Dungeon beágyazása */}
            {/*
            <section className="ai-dungeon-container">
               <h2>Játssz az AI Dungeon játékkal!</h2>
              <iframe
                src="https://play.aidungeon.io/"
                title="AI Dungeon"
                width="100%"
                height="600px"
                style={{ border: "none" }}
              ></iframe>
            </section>
            {/*
            <section className="news">
              <h2>ML (Machine Learning) training</h2>
            </section>
            Google Adsense hirdetés 
            <section className="adsense mb-4">
              <h3>Google Hirdetés</h3>
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-YOUR-ADSENSE-ID" // Google Adsense azonosító
                data-ad-slot="YOUR-AD-SLOT-ID" // Hirdetési slot ID
                data-ad-format="auto"
              ></ins>
            </section>*/}

            {/* Hírlevél feliratkozás */}
            <section className="newsletter container my-3 py-3">
              <h2>Subscribe to our newsletter!</h2>
              <p>Don't miss out on the latest trends and innovations in AI.</p>
              <form ref={formRef} onSubmit={sendEmail}>
                <input
                  type="email"
                  name="user_email" // Fontos: illeszkedjen az EmailJS sablonhoz
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className=" my-3 py-3" type="submit">
                  Subscription
                </button>
                <p id="form-message" className={messageClass}>
                  {message}
                </p>
              </form>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeEn;
